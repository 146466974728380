import React, { useState, useEffect } from "react";
import axios from "axios";
import "./contentStyle.css";
import pdficon from "./../Asset/PDF.png";
import passschema from "./../Asset/passschema.png";
import acceptschema from "./../Asset/acceptschema.png";
import failedschema from "./../Asset/failedschema.png";
import { Link, useParams } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useSelector } from "react-redux";
import { Helmet } from 'react-helmet';
import { ToastContainer, toast } from 'react-toastify';
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Rightsidebar from "./../Rightsidebar/Rightsidebar";
import { useNavigate } from "react-router-dom";
import { Commonfun } from './../Commonfun';

export default function ContentauditDetail() {
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobDate, setjobDate] = useState("");
  const [toggle, setToggle] = useState(false);
  const [faqData,setfaqData]=useState("2")
const [initialfaqDateval,SetInitialfaqDateval] = useState([]);
const [apiopen, setapiOpen] = React.useState(false);
const [apierrorMsg,setapierrorMsg]=useState();
const navigate = useNavigate();
  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value

  let querystring = useParams();
  let cont_audit_id = querystring.id;

  useEffect(() => {
    callUserdataApi();
    faqdatafun();
  }, []);
  const callUserdataApi = async () => {
    setLoading(true);
    const datavalue = await axios.get(
      `${process.env.REACT_APP_API_URL}content/v2/analysis_summary_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${cont_audit_id}`,{
        headers: {
          Authorization: `${ApiToken}`,
        },
      }
    ).catch((error) => {
      if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
        setapiOpen(true);
        setapierrorMsg(error?.response?.data?.error)
      }
      else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
        navigate('/errorpages')    
      }
      // console.log("kannan",error)
    });
    const dataval = datavalue?.data;
    setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY "));

    setCardData(dataval);
  };
  const pdfdown = async () =>{ 

    setLoading(true);
    try{
    const response = await fetch(`${process.env.REACT_APP_API_URL}content/v2/analysis_summary_detail/?user_domain_id=${esodata.domaindata.value.header_selected}&id=${cont_audit_id}&formats=pdf`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/pdf',
                    Authorization: `${ApiToken}`,
                },
            });
            // Check if the request was successful (status code 200)
            if (response.ok) {
                // Convert the response to a blob
                const blob = await response.blob();

                // Create a temporary anchor element
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                var filedata=await Commonfun("AnalysisSummaryDetail","pdf", ApiToken,esodata.domaindata.value.header_selected);
                // return false;
                link.download = filedata;
                link.click();

                // Remove the anchor element
                window.URL.revokeObjectURL(link.href);
            } else {
              toast.error("No Data", {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                console.error('Failed to fetch PDF:', response.status, response.statusText);
            }
        } catch (error) {
          toast.error("No Data", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            });
            console.error('Error during PDF download:', error);
        }
        setLoading(false);

  }
  const faqdatafun = async(menubutval="") => {
    var datavar=`${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=11`
  
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        if(error?.response?.status==599 || error?.response?.status==400 || error?.response?.status==500){
          setapiOpen(true);
          setapierrorMsg(error?.response?.data?.error)
        }
        else if(error?.code =="ERR_NETWORK" ||  error?.response?.status==404){
          navigate('/errorpages')    
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
       console.log("domain data",doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) {}
  }

  const handleToggle = () => {
    if(faqData!=1){
      setfaqData(1)
    }
    else{
      setfaqData(2)
    }
        setToggle((pre) => !pre);
  };
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') || 
    (event.target.classList[0] === 'nav_key_txt') || 
    (event.target.classList[0] === 'cnt_hdr_top_wrap') ||(event.target.classList[0] === 'react-dropdown-select') 
    ||(event.target.classList[0] === 'react-dropdown-select-content') 
    ||(event.target.classList[0] === 'react-dropdown-select-item') 
    || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap')   || (event.target.classList[0] === 'App')  
    || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
     || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row') 
     || (event.target.classList[0] === 'MuiDataGrid-cell')  || (event.target.classList[0] === 'MuiDataGrid-cellContent')
     || (event.target.classList[0] === 'header_inner_btm_wrap')||(event.target.classList[0] === 'over_all_container') 
     ||(event.target.classList[0] === 'input_domain_field') ||(event.target.classList[0] === 'react-dropdown-select-input') 
     ||(event.target.classList[0] === 'cnt_hdr_content_table') ||(event.target.classList[0] === 'hor_line')
     ||(event.target.classList[0] === 'box_wrap')||(event.target.classList[0] === 'table') ||(event.target.classList[0] === 'undefined')  ) {
      setfaqData(2)
      setToggle(false)   
    }
});
  return (
    <div className="over_all_container">
        <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
        <Helmet>
                <meta charSet="utf-8" />
                <title>Analysis Summary Detail</title>
            </Helmet>
    <div className="cnt_audit_outer_wrap ContentAuditWrap">
      <div className="cnt_hdr_top_wrap">
        <div class="box_wrap schema_anaysis_outer_wrap">
          <div className="cnt_hdr_top_lft_wrap">
            <h2>Analysis Summary Detail Report For</h2>
            <p><b>{cardData.url}</b></p>
            <p>as of {jobDate}</p>
          </div>
          <div className="cnt_hdr_top_rgt_wrap">
          <div className={`App ${faqData=="1"?'faq_move_class':""}`} >
            <button onClick={handleToggle} className="faq_class"  title="Frequently Asked Question">
            <QuestionMarkIcon />
              
            </button>
           {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />} 
          </div>
          <img src={pdficon} alt="pdf icon" onClick={pdfdown}/>
          </div>
        </div>
      </div>
     

      <div className="cnt_hdr_content_table">
        <div className="box_wrap">
          <div className="hor_line">
            <h3>Passed</h3>
          </div>
          <table className="table">
            <col style={{ width: "5%" }} />
            <col style={{ width: "35%" }} />
            <col style={{ width: "50%" }} />
            <col style={{ width: "20%" }} />
            <tbody>
              {cardData.passed_audit?.map((row, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{row.description}</td>
                  <td      dangerouslySetInnerHTML={{__html: row.result}}></td>
                  <td>
                    <img src={passschema} alt="pass" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="cnt_hdr_content_table">
        <div className="box_wrap">
          <div className="hor_line">
            <h3>Acceptable</h3>
          </div>
          <table className="table">
            <col style={{ width: "5%" }} />
            <col style={{ width: "35%" }} />
            <col style={{ width: "50%" }} />
            <col style={{ width: "20%" }} />
            <tbody>
              {cardData.okay_audit?.map((row, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{row.description}</td>
                  <td      dangerouslySetInnerHTML={{__html: row.result}}></td>
                  <td>
                    <img src={acceptschema} alt="accept" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="cnt_hdr_content_table">
        <div className="box_wrap">
          <div className="hor_line">
            <h3>Failed</h3>
          </div>
          <table className="table">
            <col style={{ width: "5%" }} />
            <col style={{ width: "35%" }} />
            <col style={{ width: "50%" }} />
            <col style={{ width: "20%" }} />
            <tbody>
              {cardData.failed_audit?.map((row, index) => (
                <tr>
                  <td>{index + 1}</td>
                  <td>{row.description}</td>
                  <td      dangerouslySetInnerHTML={{__html: row.result}}></td>
                  <td>
                    <img src={failedschema} alt="failed" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
    </div>
  );
}