import React, { useState, useEffect, useLayoutEffect } from "react";
import ChainIcon from "../Asset/New_Assert/ESO_Icon/TotalLinks.svg";
import BrokenIcon from "../../../Icons/broken-link.png";
import BrowseImageIcon from "../../../Icons/browse-images.png";
import ExternalLinkIcon from "../../../Icons/external-link.png";
import TrophyPng from "../Asset/New_Assert/ESO_Icon/Ranked_Keywords.svg";
import DocumentPng from "../Asset/New_Assert/ESO_Icon/OnPageError.svg";
import VisibilityPng from "../Asset/New_Assert/ESO_Icon/Visiblity.svg";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import Select from "react-dropdown-select";
import Loader from "../CommonLoader";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useNavigate } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import SquareIcon from "@mui/icons-material/Square";
import ErrorIcon from '@mui/icons-material/Error';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { SetredirectValue } from "../../../Store/PageredirectSlice";
import Rightsidebar from "../Rightsidebar/Rightsidebar";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LiquidFillGauge from "react-liquid-gauge";
import { interpolateRgb } from "d3-interpolate";
import { color } from "d3-color";


const MainDashboard = (props) => {
  const navigate = useNavigate();
  const [cardData, setCardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const rows = cardData ?? [];
  const esodata = useSelector((state) => state);
  const ApiToken = esodata.apiauthkey.value;

  const [TotalExternalLinks, SetTotalExternalLinks] = useState(0);
  const [InvalidLinks, SetInvalidLinks] = useState(0);
  const [ImageVideoLinks, SetImageVideoLinks] = useState(0);
  const [RootDomainsLinks, SetRootDomainsLinks] = useState(0);
  const [completedate, setcompletedate] = useState("");
  const [searchengine, setsearchengine] = useState("");
  const [country, setcountry] = useState("");
  const [selectval, setSelectval] = useState("");
  const [selectvalengine, setselectvalengine] = useState("");
  const [selectvalcountry, setselectvalcountry] = useState("");

  const [selectvalengineTop, setselectvalengineTop] = useState("");
  const [selectvalcountryTop, setselectvalcountryTop] = useState("");

  const [TopFiveMetrics, SetTopFiveMetrics] = useState([]);
  const [SelectdMetrics, SetSelectdMetrics] = useState("");
  const [SelectdGraphMetrics, SetSelectdGraphMetrics] = useState("line");
  const [ShowTraffic, SetShowTraffic] = useState(false);
  const [ShowTop, SetShowTop] = useState(false);
  const [ShowTrafficMsg, SetShowTrafficMsg] = useState('')
  const [ShowTopMsg, SetShowTopMsg] = useState('')
  const [faqData, setfaqData] = useState("2")
  const [toggle, setToggle] = useState(false);
  const [initialfaqDateval, SetInitialfaqDateval] = useState([]);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpenTop, setIsOpenTop] = React.useState(false);
  const [validation, setValidation] = useState({
    dateerror: "",
  });
  const [validationTop, SetvalidationTop] = useState("");
  let errors = { ...validation };
  const [dashboardcount, setdashboardcount] = useState(1)

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );

  const [jobDate, SetjobDate] = useState("");
  const [RankedKeywords, SetRankedKeywords] = useState(0);
  const [OnPageErrors, SetOnPageErrors] = useState(0);
  const [TotalInternalLinks, SetTotalInternalLinks] = useState(0);
  const [VisibilityScore, SetVisibilityScore] = useState(0);

  const [HighIssuesData, SetHighIssuesData] = useState([]);
  const [MediumIssuesData, SetMediumIssuesData] = useState([]);
  const [LowIssuesData, SetLowIssuesData] = useState([]);

  const [HighCount, SetHighCount] = useState(0);
  const [MediumCount, SetMediumCount] = useState(0);
  const [LowCount, SetLowCount] = useState(0);
  const [TotalCount, SetTotalCount] = useState(0);
  const [widgetcount, SetWidgetcount] = useState(0);
  const [apiopen, setapiOpen] = React.useState(false);
  const [apierrorMsg, setapierrorMsg] = useState();

  const apihandleClose = () => {
    setapiOpen(false);
  };
  const dispatch = useDispatch();

  function roundToTwoDecimalPlaces(number) {
    return parseFloat(number.toFixed(2));
  }

  const columns = [
    {
      field: "sid",
      headerName: "#",
      width: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      description: "Index of the row",
    },

    {
      field: "url",
      headerName: "Url",
      width: 400,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
    },
    {
      field: "changed_metrics",
      headerName: removeUnderscore(SelectdMetrics),
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
    },
    {
      field: "avg_ranking",
      headerName: "Avg.Rank",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
    },
    {
      field: "this_week_trend",
      headerName: "This Week Trend",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
    },
    {
      field: "last_week_trend",
      headerName: "Last Week Trend",
      width: 150,
      headerAlign: "center",
      align: "center",
      sortable: true,
      disableClickEventBubbling: true,
    },
    {
      field: "changes",
      headerName: "Changes",
      width: 150,
      headerAlign: "center",
      align: "center",
      disableClickEventBubbling: true,
      sortable: true,
      renderCell: (cardData) => {
        if (cardData.row.changes === "low") {
          return (
            <div className="gain_drop_row" style={{ color: "##ff0000" }}>
              <TrendingDownIcon />
            </div>
          );
        }

        if (cardData.row.changes === "high") {
          return (
            <div className="gain_drop_row" style={{ color: "#008900" }}>
              <TrendingUpIcon />
            </div>
          );
        }

        if (cardData.row.changes === "equal") {
          return (
            <div
              className="gain_drop_row no_change_row"
              style={{ color: "orange" }}
            >
              <SquareIcon />
            </div>
          );
        }
      },
    },
  ];

  const GetdashboardData = async () => {
    setLoading(true);
    SetRankedKeywords(0);
    SetOnPageErrors(0);
    SetTotalInternalLinks(0);
    SetVisibilityScore(0);

    var JobId = "";

    var RankJobId = "";
    var RankCountryId = "";
    var RankEngineId = "";

    if (selectval !== "") {
      if (selectval[0].id !== undefined && selectval[0].id !== "") {
        RankJobId = selectval[0].id;
      }
    }

    if (selectvalcountry !== "") {
      if (
        selectvalcountry[0].search_engine_locale__city__state__country__id !==
        undefined &&
        selectvalcountry[0].search_engine_locale__city__state__country__id !==
        ""
      ) {
        RankCountryId =
          selectvalcountry[0].search_engine_locale__city__state__country__id;
      }
    }

    if (selectvalengine !== "") {
      if (
        selectvalengine[0].search_engine_locale__search_engine__id !==
        undefined &&
        selectvalengine[0].search_engine_locale__search_engine__id !== ""
      ) {
        RankEngineId =
          selectvalengine[0].search_engine_locale__search_engine__id;
      }
    }

    //rank and visibility

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}dashboard/ranked_keywords/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${RankJobId}&rank_section=rank_summary&country_id=${RankCountryId}&search_engine_id=${RankEngineId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {

        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }

        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalue?.data) {
        SetRankedKeywords(datavalue.data.ranked_keyword_count);
        SetVisibilityScore(datavalue.data.visibility_score + "%");
      }
    } catch (err) {
      console.log(err);
    }

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}dashboard/on_page_errors/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalue?.data) {
        SetOnPageErrors(datavalue.data.total_on_page_errors_count);
      }
    } catch (err) {
      console.log(err);
    }

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}dashboard/dashboard_total_internal_link_count/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data) {
        SetTotalInternalLinks(datavalue.data.total_internal_links);
      }
    } catch (err) {
      console.log(err);
    }

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}dashboard/site_audit_summary/?user_domain_id=${esodata.domaindata.value.header_selected}&job_id=${JobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalue?.data) {
        if (datavalue?.data?.top_two_high_issues) {
          SetHighIssuesData(datavalue.data.top_two_high_issues);
        }
        if (datavalue?.data?.top_two_medium_issues) {
          SetMediumIssuesData(datavalue.data.top_two_medium_issues);
        }
        if (datavalue?.data?.top_two_low_issues) {
          SetLowIssuesData(datavalue.data.top_two_low_issues);
        }
        if (datavalue?.data?.total_count) {
          SetTotalCount(datavalue.data.total_count);
        }
        if (datavalue?.data?.medium_count) {
          SetMediumCount(datavalue.data.medium_count);
        }
        if (datavalue?.data?.high_count) {
          SetHighCount(datavalue.data.high_count);
        }
        if (datavalue?.data?.low_count) {
          SetLowCount(datavalue.data.low_count);
        }
      }


    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };
  const pageRedirectfun = (e) => {
    localStorage.setItem("eso_redirect_value", e.currentTarget.id);
    dispatch(SetredirectValue(e.currentTarget.id));
    navigate('/internal-link-checker')

  }
  useLayoutEffect(() => {
    GetuserDatavalue();
    GetwidgetDatavalue();
    GetdashboardData();
    faqdatafun()
  }, []);
  const GetuserDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}accounts/get_user_details/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (datavalue?.data) {
        if (datavalue?.data?.is_onboarding_completed !== true)
          window.location.href = `${process.env.REACT_APP_DOMAIN}Userdata`;
      }

    } catch (err) {
      console.log(err);
    }
  };
  const GetwidgetDatavalue = async () => {
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}dashboard/wcag_accessibility_score/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      SetWidgetcount(datavalue?.data?.data?.score)
    } catch (err) {
      console.log(err);
    }
  };
  function capitalizeEachWord(str) {
    let words = str.split(" ");
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }
    return words.join(" ");
  }

  const replacedata = (value) => {
    var Data = value;
    Data = Data.replaceAll("_", " ");
    Data = capitalizeEachWord(Data);
    return Data;
  };

  const OpenRankFilter = () => {
    setIsOpen(true);
  };
  const OpenRankFilterTop = () => {
    setIsOpenTop(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };
  const closeModalTop = () => {
    setIsOpenTop(false);
  };

  const FilterFunctionRank = () => {
    GetdashboardData();
    closeModal();
    setSelectval("");
    setselectvalengine("");
    setselectvalcountry("");
  };

  const LoadFilterOptions = async (type = "") => {
    if (type === "rank") {
      try {
        const datavalue = await axios.get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?user_domain_id=${esodata.domaindata.value.header_selected}&job_type=rankings_summary`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        ).catch((error) => {
          setLoading(false);
          if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
            if (dashboardcount == 1) {
              setapiOpen(true);
              setapierrorMsg(error?.response?.data?.error)
              setdashboardcount(2)
            }
          }
          else if (error?.code == "ERR_NETWORK") {
            navigate('/errorpages')
          }
          // console.log("kannan",error)
        });

        if (datavalue?.data) {
          setcompletedate(datavalue.data.jobs);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const LoadSearchEngine = async (jobid = "") => {
    setsearchengine([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?user_domain_id=${esodata.domaindata.value.header_selected}&filter_type=search_engine&job_id=${jobid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalue?.data) {
        setsearchengine(datavalue.data.search_engines);
      }


    } catch (err) {
      console.log(err);
    }
  };

  const LoadCountry = async (jobid = "") => {
    setcountry([]);

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}keywords/ranking_filter/?user_domain_id=${esodata.domaindata.value.header_selected}&filter_type=country&job_id=${jobid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalue?.data) {
        console.log(datavalue.data.countries)
        setcountry(datavalue.data.countries);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const TriggersetSelectval = (values) => {
    setSelectval(values);
    LoadSearchEngine(values[0].id);
    LoadCountry(values[0].id);
  };

  const Navigateto = (e) => {
    var ClassName = e.target.tagName;
    if (ClassName !== "path" || ClassName === "svg") {
      const LinkAttr = e.target
        .closest(".ranking_summary_col")
        .getAttribute("data-link");

      navigate(LinkAttr);
    }
  };

  const [SeriesDate, SetSeriesDate] = useState([]);
  const [Direct, SetDirect] = useState([]);
  const [OrganicSearch, SetOrganicSearch] = useState([]);
  const [OrganicSocial, SetOrganicSocial] = useState([]);
  const [PaidSearch, SetPaidSearch] = useState([]);
  const [Referral, SetReferral] = useState([]);
  const [Unassigned, SetUnassigned] = useState([]);
  const [pieseriesData, SetpieseriesData] = useState([])

  const [Directpie, SetDirectpie] = useState(0);
  const [OrganicSearchpie, SetOrganicSearchpie] = useState(0);
  const [OrganicSocialpie, SetOrganicSocialpie] = useState(0);
  const [PaidSearchpie, SetPaidSearchpie] = useState(0);
  const [Referralpie, SetReferralpie] = useState(0);
  const [Unassignedpie, SetUnassignedpie] = useState(0);

  const LastSevenDaysTrafficReport = async () => {
    setLoading(true);
    SetSeriesDate([]);
    SetDirect([]);
    SetOrganicSearch([]);
    SetOrganicSocial([]);
    SetPaidSearch([]);
    SetReferral([]);
    SetUnassigned([]);

    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}dashboard/traffic_report_graph/?user_domain_id=${esodata.domaindata.value.header_selected}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalue?.data) {
        if (datavalue?.data?.traffic_result) {
          const datesArray = datavalue.data.traffic_result.map(
            (item) => item.date
          );
          const DirectArray = datavalue.data.traffic_result.map(
            (item) => item.channel.direct
          );
          const OrganicSearchArray = datavalue.data.traffic_result.map(
            (item) => item.channel["organic search"]
          );
          const OrganicSocialArray = datavalue.data.traffic_result.map(
            (item) => item.channel["organic social"]
          );
          const PaidSearchArray = datavalue.data.traffic_result.map(
            (item) => item.channel["paid search"]
          );
          const ReferralArray = datavalue.data.traffic_result.map(
            (item) => item.channel.referral
          );
          const UnassignedArray = datavalue.data.traffic_result.map(
            (item) => item.channel.unassigned
          );
          SetSeriesDate(datesArray);
          SetDirect(DirectArray);
          SetOrganicSearch(OrganicSearchArray);
          SetOrganicSocial(OrganicSocialArray);
          SetPaidSearch(PaidSearchArray);
          SetReferral(ReferralArray);
          SetUnassigned(UnassignedArray);
          setLoading(false);
          SetShowTraffic(true)


          var PaidSearch = 0
          var Direct = 0
          var Unassigned = 0
          var OrganicSocial = 0
          var OrganicSearch = 0
          var Referral = 0

          if (datavalue.data.pie_chart) {


            PaidSearch = datavalue.data.pie_chart?.channel['paid search']
            Direct = datavalue.data.pie_chart.channel.direct
            Unassigned = datavalue.data.pie_chart.channel.unassigned
            OrganicSocial = datavalue.data.pie_chart.channel['organic social']
            OrganicSearch = datavalue.data.pie_chart.channel['organic search']
            Referral = datavalue.data.pie_chart.channel.referral
            if (PaidSearch != "" || !PaidSearch)
              PaidSearch = 0;
          }
          SetPaidSearchpie(PaidSearch)
          SetDirectpie(Direct)
          SetUnassignedpie(Unassigned)
          SetOrganicSocialpie(OrganicSocial)
          SetOrganicSearchpie(OrganicSearch)
          SetReferralpie(Referral)
          SetpieseriesData([PaidSearch, Direct, Unassigned, OrganicSocial, OrganicSearch, Referral])

        }

        if (datavalue.data.status) {
          if (datavalue.data.status === true || datavalue.data.status === 'error') {
            SetShowTrafficMsg(datavalue.data.message)
            SetShowTraffic(false)
          }
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };
  const options = {
    chart: {
      offsetX: 20, // Increase the left offset
      offsetY: 10, // Optional: adjust vertical offset if needed
      height: 350,
      type: "area",
      toolbar: {
        show: false,
      },
    },
    grid: {
      padding: {
        left: 20, // Add more left padding
        right:20,
       
      },
    },
    xaxis: {
      categories: SeriesDate,
      labels: {
        style: {
          colors: '#000', // Color of the labels
        },
        offsetX: 5, // Move the label to the right
      },
    },
    // Other options...
  };
  

  // const options = {
  //   chart: {
  //     offsetX: 10, 
  //     height: 350,
  //     type: "area",
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   grid: {
  //     show: true,
  //     borderColor: "#90A4AE",
  //     strokeDashArray: 0,
  //     position: "back",
  //     xaxis: {
  //       lines: {
  //         show: false,
  //       },
  //     },
  //     yaxis: {
  //       lines: {
  //         show: false,
  //       },
  //     },
  //     row: {
  //       colors: undefined,
  //       opacity: 0.5,
  //     },
  //     column: {
  //       colors: undefined,
  //       opacity: 0.5,
  //     },
  //     padding: {
  //       top: 0,
  //       right: 0,
  //       bottom: 0,
  //       left: 0,
  //     },
  //   },
  //   toolbar: {
  //     show: false,
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "smooth",
  //   },
  //   xaxis: {
  //     categories: SeriesDate,
  //   },
  // };

  const pieseries = pieseriesData
  console.log("pieseries", pieseries)
  const pieoptions = {
    chart: {
      width: 100,
      type: 'donut',
    },

    tooltip: {
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {

        return "<div class='custom_pie_tool_tip'>" + w.config.labels[seriesIndex] + "</div>";
      }

    },
    dataLabels: {
      enabled: false
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 100
        },
        legend: {
          show: false
        }
      }
    }],
    legend: {
      position: 'right',
      offsetY: 0,
      height: 230,
    },
    labels: ['Paid Search - ' + PaidSearchpie, 'Direct - ' + Directpie, 'Unassigned - ' + Unassignedpie, 'Organic Social - ' + OrganicSocialpie, 'Organic Search - ' + OrganicSearchpie, 'Referral - ' + Referralpie]
  }


  const series = [
    {
      name: "Direct",
      data: Direct,
    },
    {
      name: "Organic Search",
      data: OrganicSearch,
    },
    {
      name: "Organic Social",
      data: OrganicSocial,
    },
    {
      name: "Paid Search",
      data: PaidSearch,
    },
    {
      name: "Referral",
      data: Referral,
    },
    {
      name: "Unassigned",
      data: Unassigned,
    },
  ];

  const [dEngineoption, SetdEngineoption] = useState([]);
  const [dcountryoption, Setdcountryoption] = useState([]);

  const TopFiveTrafficPages = async (
    metrictype = "",
    search_engine_id = "",
    search_locale = ""
  ) => {
    setLoading(true);
    setCardData([]);
    try {

      var PassUrl = `${process.env.REACT_APP_API_URL}dashboard/top5_traffic_table/?user_domain_id=${esodata.domaindata.value.header_selected}&top5_param=${metrictype}`

      if (search_engine_id !== '') {
        PassUrl = PassUrl + '&search_engine=' + search_engine_id;
      }
      if (search_locale !== '') {
        PassUrl = PassUrl + '&search_locale=' + search_locale;
      }

      const datavalue = await axios.get(
        PassUrl,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalue?.data?.result) {
        var newData = datavalue.data.result.map((obj, index) => {
          return { ...obj, sid: index + 1 };
        });

        if (metrictype === "Bounce_Rate") {
          newData = newData.map((item) => {
            // Create a new object with the desired key change
            return { ...item, changed_metrics: item.bounce_rate };
          });
        } else if (metrictype === "Sessions") {
          newData = newData.map((item) => {
            // Create a new object with the desired key change
            return { ...item, changed_metrics: item.sessions };
          });
        } else if (metrictype === "New_Users") {
          newData = newData.map((item) => {
            // Create a new object with the desired key change
            return { ...item, changed_metrics: item.new_users };
          });
        } else if (metrictype === "CTR%") {
          newData = newData.map((item) => {
            // Create a new object with the desired key change
            return { ...item, changed_metrics: item.ctr };
          });
        }

        setCardData(newData);
        SetShowTop(true)
        setLoading(false);
      }

      if (datavalue?.data?.status) {
        if (datavalue.data.status === true || datavalue.data.status === 'error') {
          SetShowTopMsg(datavalue.data.message)
          SetShowTop(false)
        }
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const FilterFunctionRankTop = async () => {
    SetvalidationTop("");
    var Engine = '';
    var Country = '';
    if (selectvalengineTop !== "") {
      Engine =
        selectvalengineTop[0].search_engine_locale__search_engine__id;
    }
    if (selectvalcountryTop !== "") {
      Country =
        selectvalcountryTop[0].search_engine_locale__city__state__country__id;
    }

    TopFiveTrafficPages(SelectdMetrics, Engine, Country);
    closeModalTop();
  };

  const TopFiveMetricsList = async () => {
    SetTopFiveMetrics([]);
    try {
      const datavalue = await axios.get(
        `${process.env.REACT_APP_API_URL}dashboard/top5_metrics_list/`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });

      if (datavalue?.data) {
        SetSelectdMetrics(datavalue.data.top5_metrics[0]);
        TopFiveTrafficPages(datavalue.data.top5_metrics[0]);
        SetTopFiveMetrics(datavalue.data.top5_metrics);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const menuChange = (e) => {
    var Engine = "";
    var Country = "";
    if (selectvalengineTop !== "" && selectvalcountryTop !== "") {
      Engine = selectvalengineTop[0].search_engine_locale__search_engine__id;
      Country =
        selectvalcountryTop[0].search_engine_locale__city__state__country__id;
    }

    e.preventDefault();
    SetSelectdMetrics(e.target.id);
    TopFiveTrafficPages(e.target.id, Engine, Country);
  };

  useLayoutEffect(() => {
    LoadFilterOptions("rank");
    LoadSearchEngine();
    LoadCountry();
    LastSevenDaysTrafficReport();

    TopFiveMetricsList();
  }, []);

  function removeUnderscore(inputString) {
    // Use the replace method with a regular expression to replace underscores with an empty string
    return inputString.replace(/_/g, " ");
  }

  const menuChangeBar = (e) => {
    SetSelectdGraphMetrics(e.target.id);
  };
  const seoMetricData = (data) => {
    if (replacedata(data.seo_metrics) == "Missing Alt Tags")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/18" className="seometricdata"><div className="value">{data.issue_count}
          
        </div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Hreflang")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/9" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Missing Canonical")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/14" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Duplicate Titles")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/4" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Duplicate Descriptions")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/5" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Missing H1 Tags")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/16" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Missing Descriptions")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/15" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Multiple H1 Tags")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/19" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Duplicate H1 Tags")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/7" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Missing Titles")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/2" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Mismatch Canonical")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/3" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "No-index robot")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/17" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Images Available")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/6" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "H1 Tags Available")
      return <div className="data">
        <Link to="/onpage-crawl-data/2/20" className="seometricdata"><div className="value">{data.issue_count}</div>
          <div>{replacedata(data.seo_metrics)}</div></Link>
      </div>
    else if (replacedata(data.seo_metrics) === "Broken Links 4xx Link")
      return <div className="data">
        <div className="value">{data.issue_count}</div>
        <div onClick={pageRedirectfun} id="1" >{replacedata(data.seo_metrics)}</div>
      </div>
    else if (replacedata(data.seo_metrics) === "Server Error 5xx Link")
      return <div className="data">
        <div className="value">{data.issue_count}</div>
        <div onClick={pageRedirectfun} id="2">{replacedata(data.seo_metrics)}</div>
      </div>
  }
  const e = document.getElementById('root');
  e.addEventListener('click', function handleClick(event) {
    console.log(event.target.classList[0])
    if ((event.target.classList[0] === 'cnt_hdr_blw_wrap') || (event.target.classList[0] === 'header_wrap') ||
      (event.target.classList[0] === 'nav_key_txt') ||
      (event.target.classList[0] === 'cnt_hdr_top_wrap') || (event.target.classList[0] === 'react-dropdown-select')
      || (event.target.classList[0] === 'react-dropdown-select-content')
      || (event.target.classList[0] === 'react-dropdown-select-item')
      || (event.target.classList[0] === 'site_cnt_hdr_blw_wrap') || (event.target.classList[0] === 'App')
      || (event.target.classList[0] === 'cnt_audit_outer_wrap') || (event.target.classList[0] === 'MuiDataGrid-columnHeaderTitleContainer')
      || (event.target.classList[0] === 'MuiDataGrid-toolbarContainer') || (event.target.classList[0] === 'MuiDataGrid-row')
      || (event.target.classList[0] === 'MuiDataGrid-cell') || (event.target.classList[0] === 'MuiDataGrid-cellContent')
      || (event.target.classList[0] === 'header_inner_btm_wrap') || (event.target.classList[0] === 'over_all_container')
      || (event.target.classList[0] === 'input_domain_field') || (event.target.classList[0] === 'react-dropdown-select-input')
      || (event.target.classList[0] === 'run_rpt_btn') || (event.target.classList[0] === 'input_col') || (event.target.classList[0] === 'plagarism_analyzer_row')
      || (event.target.classList[0] === 'MuiDataGrid-cell--withRenderer') || (event.target.classList[0] === 'link_rn_rpt_btn')
      || (event.target.classList[0] === 'drop-organicsearch') || (event.target.classList[0] === 'link_rn_rpt_btn')

    ) {

      setfaqData(2)
      setToggle(false)
    }
  });
  const faqdatafun = async (menubutval = "") => {
    var datavar = `${process.env.REACT_APP_API_URL}domain/frequently_asked_questions/?menu=52`
    try {
      const doaminsectiondatavalue = await axios.get(
        datavar,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      ).catch((error) => {
        setLoading(false);
        if (error?.response?.status == 599 || error?.response?.status == 500 || error?.response?.status == 400 || error?.response?.status == 404) {
          if (dashboardcount == 1) {
            setapiOpen(true);
            setapierrorMsg(error?.response?.data?.error)
            setdashboardcount(2)
          }
        }
        else if (error?.code == "ERR_NETWORK") {
          navigate('/errorpages')
        }
        // console.log("kannan",error)
      });
      if (doaminsectiondatavalue?.data) {
        console.log("domain data", doaminsectiondatavalue?.data?.faqs)
        SetInitialfaqDateval(doaminsectiondatavalue?.data?.faqs)
      }
    } catch (err) { }
  }
  const handleToggle = () => {
    if (faqData != 1) {
      setfaqData(1)
    }
    else {
      setfaqData(2)
    }
    setToggle((pre) => !pre);
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "rgb(255,174,31)";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
  return (

    <div className="over_all_container">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard</title>
      </Helmet>
      {loading === true ? <Loader /> : null}
      <div
        style={{
          width: "calc(100% - 40px)",
          marginRight: "40px",
          marginLeft: "auto",
          marginRight: "auto",
        }}
      >
        <div className="cnt_audit_outer_wrap">
          <div className="cnt_hdr_top_wrap">
            <div className="cnt_hdr_top_lft_wrap">
              <h2>{"Dashboard"}</h2>
              {/* <p>as of {jobDate}</p> */}
            </div>

            <div className="cnt_hdr_top_rgt_wrap">
              <div className={`App ${faqData == "1" ? 'faq_move_class' : ""}`} >
                <button onClick={handleToggle} className="faq_class" title="Frequently Asked Question">
                  <QuestionMarkIcon />

                </button>
                {toggle && <Rightsidebar close={() => setToggle(false)} initialfaqDateval={initialfaqDateval} />}
              </div>
              {/* <img src={pdficon} alt="pdf icon" />
          <img src={csvicon} alt="csv icon" /> */}
            </div>
          </div>

          <div className="external_link_data_row">
            <div className="cols ranking_summary_col"
              data-link="/ranking-summary/2"
              onClick={Navigateto}
            >
              <div className="icon_col">
                <img src={TrophyPng} />
              </div>
              <div className="content_col filter_dash_col">
                <p>
                  Ranked Keywords{" "}{" "}{" "}
                  <span
                    className="filter_icon_dashboard "
                    onClick={OpenRankFilter}
                  >
                    <FilterAltIcon />
                  </span>
                </p>
                <p>{RankedKeywords}</p>
              </div>
            </div>
            <div className="cols ranking_summary_col" data-link="/onpage-crawl-data/2/1"
              onClick={Navigateto}>
              <div className="icon_col">
                <img src={DocumentPng} />
              </div>
              <div className="content_col filter_dash_col">
                <p>On Page Errors</p>
                <p>{OnPageErrors}</p>
              </div>
            </div>
            <div className="cols ranking_summary_col" data-link="/internal-link-checker"
              onClick={Navigateto}>
              <div className="icon_col">
                <img src={ChainIcon} />
              </div>
              <div className="content_col filter_dash_col">
                <p>Total Internal Links</p>
                <p>{TotalInternalLinks}</p>
              </div>
            </div>
            <div
              className="cols ranking_summary_col"
              data-link="visibility-score-detail"
              onClick={Navigateto}
            >
              <div className="icon_col">
                <img src={VisibilityPng} />
              </div>
              <div className="content_col filter_dash_col">
                <p>
                  Visibility score
                  <span
                    className="filter_icon_dashboard"
                    onClick={OpenRankFilter}
                  >
                    <FilterAltIcon />
                  </span>
                </p>
                <p>{VisibilityScore}</p>
              </div>
            </div>
          </div>
          <div className="site_audit_dashboard_outer">
            <div>
              <h3>WCAG Accessibility Score</h3>
            </div>
            <div className="card_row">
              <div className="cols">
                <div className="plagiarisam_chart">
                  <LiquidFillGauge
                    style={{ margin: "0 auto" }}
                    width={150}
                    height={150}
                    value={widgetcount}
                    percent="%"
                    textSize={1.2}
                    textOffsetX={0}
                    textOffsetY={0}
                    riseAnimation
                    waveAnimation
                    waveFrequency={3}
                    waveAmplitude={1}
                    gradient
                    gradientStops={gradientStops}
                    circleStyle={{
                      fill: textfillColor,
                    }}
                    waveStyle={{
                      fill: fillColor,
                    }}
                    textStyle={{
                      fill: color("#FFFFFF").toString(),
                      fontFamily: "Arial",
                    }}
                    waveTextStyle={{
                      fill: color("#0b2033").toString(),
                      fontFamily: "Arial",
                    }}
                  />
                </div>
              </div>


            </div>


          </div>
          <div className="site_audit_dashboard_outer">
            <div>
              <h3>Site Audit Summary</h3>
            </div>

            <div className="card_row">
              <div className="cols">
                <p className="heading">High Impact Issues</p>
                <p className="versus">
                  {HighCount} High Impact vs {TotalCount} Total
                </p>
                {HighIssuesData.length > 0 ?
                  <div className="site_data_row">
                    {HighIssuesData.map((data, index) => (
                      seoMetricData(data)

                    ))}
                  </div> : ""}
              </div>
              <div className="cols">
                <p className="heading">Medium Impact Issues</p>
                <p className="versus">
                  {MediumCount} Medium Impact vs {TotalCount} Total
                </p>
                {MediumIssuesData.length > 0 ?
                  <div className="site_data_row">
                    {MediumIssuesData.map((data, index) => (
                      // <div className="data">
                      //   <div className="value">{data.issue_count}</div>
                      //   <div>{replacedata(data.seo_metrics)}</div>
                      // </div>
                      seoMetricData(data)
                    ))}
                  </div> : ""}
              </div>
              <div className="cols">
                <p className="heading">Low Impact Issues</p>
                <p className="versus">
                  {LowCount} Low Impact vs {TotalCount} Total
                </p>
                {LowIssuesData.length > 0 ?
                  <div className="site_data_row">
                    {LowIssuesData.map((data, index) => (
                      // <div className="data">
                      //   <div className="value">{data.issue_count}</div>
                      //   <div>{replacedata(data.seo_metrics)}</div>
                      // </div>
                      seoMetricData(data)
                    ))}
                  </div> : ""}
              </div>
            </div>
          </div>

          <div className="dashboard_traffic_widget">
            {
              ShowTraffic === true ? <div className="traffic_report_seven_wrap">
                <h3>Traffic Report For Last 7 days</h3>
                <div class="site_cnt_hdr_blw_wrap dashboard_traffic_switch">
                  <button
                    type="button"
                    id="line"
                    class={`site_rn_rpt_btn ${"line" === SelectdGraphMetrics ? "active" : ""
                      }`}
                    onClick={(e) => menuChangeBar(e)}
                  >
                    Line Chart
                  </button>
                  <button
                    type="button"
                    id="bar"
                    class={`site_rn_rpt_btn ${"bar" === SelectdGraphMetrics ? "active" : ""
                      }`}
                    onClick={(e) => menuChangeBar(e)}
                  >
                    Bar Chart
                  </button>
                  <button
                    type="button"
                    id="pie"
                    class={`site_rn_rpt_btn ${"pie" === SelectdGraphMetrics ? "active" : ""
                      }`}
                    onClick={(e) => menuChangeBar(e)}
                  >
                    Pie Chart
                  </button>
                </div>
                <div>
                  {SeriesDate.length > 0 ? (
                    <div className="google_area_chart_wrap">
                      {SelectdGraphMetrics === "line" ? (
                        <div className="row">
                          <ReactApexChart
                            options={options}
                            series={series}
                            type="line"
                            height={350}
                          />
                        </div>
                      ) : null}

                      {SelectdGraphMetrics === "bar" ? (
                        <div className="row">
                          <ReactApexChart
                            options={options}
                            series={series}
                            type="bar"
                            height="100%"
                            width="100%"
                          />
                        </div>
                      ) : null}

                      {SelectdGraphMetrics === "pie" ? (
                        <div className="row pie_chart_row">
                          <ReactApexChart options={pieoptions} series={pieseries} type="donut" />
                        </div>
                      ) : null}


                    </div>
                  ) : null}
                </div>
              </div> : <div>    <h3>Traffic Report For Last 7 days</h3>{ShowTrafficMsg !== '' ? <div className="google_traffic_error"><ErrorIcon />{ShowTrafficMsg}</div> : null}</div>
            }


            {
              ShowTop === true ? <div className="top_5_traffic_wrap">
                <h3 style={{marginTop:"80px"}}>
                  Top 5 Traffic Pages{" "}
                  <span
                    className="filter_icon_dashboard top_5"
                    onClick={OpenRankFilterTop}
                  >
                    <FilterAltIcon />
                  </span>
                </h3>
                <div class="site_cnt_hdr_blw_wrap dashboard_traffic_switch">
                  {TopFiveMetrics.map((data, index) => (
                    <button
                      type="button"
                      id={data}
                      class={`site_rn_rpt_btn ${data === SelectdMetrics ? "active" : ""
                        }`}
                      onClick={(e) => menuChange(e)}
                    >
                      {removeUnderscore(data)}
                    </button>
                  ))}
                </div>
                <div className="data_table" style={{ width: "100%" }}>
                  <div style={{ height: "auto", width: "100%" }}>
                    <DataGrid
                      getRowId={(row) => row.sid}
                      slots={{
                        noRowsOverlay: MyCustomNoRowsOverlay,
                        toolbar: GridToolbar,
                      }}
                      slotProps={{
                        toolbar: {
                          showQuickFilter: false,
                        },
                      }}
                      rows={rows}
                      columns={columns}
                      sx={{
                        "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                          py: "8px",
                        },
                        "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                          py: "15px",
                        },
                        "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell":
                        {
                          py: "22px",
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                        },
                        "&, [class^=MuiDataGrid]": { border: "none" },
                      }}
                      getRowHeight={() => "auto"}
                      autoHeight
                      initialState={{
                        pagination: {
                          paginationModel: {
                            pageSize: 5,
                          },
                        },
                      }}
                      loading={loading}

                      disableColumnFilter
                      disableColumnSelector
                      disableDensitySelector
                    />
                  </div>

                  <div className="metric_notes_row">
                    <div className="row">
                      <div className="below_note_ranking icon">
                        <div className="msg">
                          {" "}
                          *Note : N/R is Not Ranked , N/A is Not Available
                        </div>
                        <div className="green">
                          <TrendingUpIcon />- High
                        </div>

                        <div className="rose">
                          <TrendingDownIcon /> - Low
                        </div>

                        <div className="orange">
                          <SquareIcon />- No Change
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> : <div> <h3>
                Top 5 Traffic Pages</h3>{ShowTopMsg !== '' ? <div className="google_traffic_error"><ErrorIcon />{ShowTopMsg}</div> : null}</div>
            }


          </div>
        </div>
      </div>

      <Dialog
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle>{"Ranking Keyword/Visibility Score"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              <div>
                <div className="">
                  <div>
                    <span>Completed Date List</span>
                    <Select
                      labelField="completed_at"
                      valueField="id"
                      options={completedate}
                      onChange={(values) => TriggersetSelectval(values)}
                    />
                  </div>
                </div>

                <div className="">
                  <div>
                    <span>Search Engine</span>
                    <Select
                      labelField="search_engine_locale__search_engine__name"
                      valueField="search_engine_locale__search_engine__id"
                      options={searchengine}
                      onChange={(values) => setselectvalengine(values)}
                    />
                  </div>
                </div>

                <div className="">
                  <div>
                    <span>Country</span>
                    <Select
                      labelField="search_engine_locale__country__name"
                      valueField="search_engine_locale__country__id"
                      options={country}
                      onChange={(values) => setselectvalcountry(values)}
                    />
                  </div>
                </div>

                <div className="col">
                  <span className="text-error">{validation.dateerror}</span>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="run_rpt_btn rn_rpt_btn"
            onClick={FilterFunctionRank}
            style={{ cursor: "pointer" }}
          >
            Submit
          </button>
          <button style={{ cursor: "pointer" }} onClick={closeModal}>
            close
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={modalIsOpenTop}
        onClose={closeModalTop}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter job_date_filter"
      >
        <DialogTitle>{"Top 5 Traffic Pages"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
              <div>
                <div className="">
                  <div>
                    <span>Search Engine</span>
                    <Select
                      values={[dEngineoption]}
                      labelField="search_engine_locale__search_engine__name"
                      valueField="search_engine_locale__search_engine__id"
                      options={searchengine}
                      onChange={(values) => {
                        setselectvalengineTop(values);
                        // SetdEngineoption({
                        //   search_engine_locale__search_engine__name:
                        //     values[0].search_engine_locale__search_engine__name,
                        //   search_engine_locale__search_engine__id:
                        //     values[0].search_engine_locale__search_engine__id,
                        // });
                      }}
                    />
                  </div>
                </div>

                <div className="">
                  <div>
                    <span>Country</span>
                    <Select
                      values={[dcountryoption]}
                      labelField="search_engine_locale__country__name"
                      valueField="search_engine_locale__country__id"
                      options={country}
                      onChange={(values) => {
                        setselectvalcountryTop(values);
                        Setdcountryoption({
                          search_engine_locale__country__name:
                            values[0]
                              .search_engine_locale__country__name,
                          search_engine_locale__country__id:
                            values[0]
                              .search_engine_locale__country__id,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="col">
                  <span className="text-error">{validationTop}</span>
                </div>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            className="run_rpt_btn rn_rpt_btn"
            onClick={FilterFunctionRankTop}
            style={{ cursor: "pointer" }}
          >
            Submit
          </button>
          <button style={{ cursor: "pointer" }} onClick={closeModalTop}>
            close
          </button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={apiopen}
        onClose={apihandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{"Alert!! "}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {apierrorMsg}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={apihandleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default MainDashboard;
