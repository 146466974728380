import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import axios from "axios";
import "./Siteauditstyle.css";
import calendarpng from "./../Asset/New_Assert/ESO_Icon/Filter.svg";
import pdficon from "./../Asset/PDF.png";
import csvicon from "./../Asset/CSV.png";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Bar } from "react-chartjs-2";
import Chart from "chart.js/auto";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Select from "react-dropdown-select";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import { GetCompetitorJobIds } from "../../../Store/CompetitiorJobId";
import { GetCompetitorJobIds as GetCompetitorDomainIds  } from "../../../Store/CompetitiorDomainId";
import { GetCompetitorJobIds as GetCompetitorInJobIds  } from "../../../Store/CompetitorInJobId";
import Loader from "../CommonLoader";


import { useSelector } from "react-redux";

export default function CompetitorTest(props) {


  const [cardData, setCardData] = useState([]);
  const [sitecardData, setSitecardData] = useState([]);
  const [internalcardData, setInternalcardData] = useState([]);
  const [seoindexData, setSeoindexData] = useState([]);
  const [seooccurData, setSeooccurData] = useState([]);
  const [jobidData, setjobidData] = useState("");
  const [loading, setLoading] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [jobDate, setjobDate] = useState("");
  const [completedate, setcompletedate] = useState("");
  const [selectval, setSelectval] = useState("");
  const [competitorcardData, setCompetitorcardData] = useState([]);
  const [competitorsitecardData, setCompetitorSitecardData] = useState([]);
  const [competitorinternalcardData, setCompetitorInternalcardData] = useState([]);
  const [value, setValue] = useState("1");
  const [domainval,Setdomainval]=useState('')
  const [arrheaderdata, setArrheaderdata] = useState([]);
  const [earlierdataarr, setEarlierdataarr] = useState([]);
  const [latestdataarr, setLatestdataarr] = useState([]);
  const [serveritydata, setServeritydata] = useState([]);
  const [latestoccurData, setLatestoccurData] = useState([]);
  const [dEngineoption, SetdEngineoption] = useState({
    completed_at:
      'Select Date',
      id:
      'Select Date',
  });
  const [autoheightval,SetAutoheightval]=useState(true)


  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  const MyCustomNoRowsOverlay = () => (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  )

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  var startColor = "#6495ed"; // cornflowerblue
  var endColor = "#dc143c"; // crimson
  const interpolate = interpolateRgb(startColor, endColor);
  const fillColor = "#fe874e";
  const textfillColor = "rgb(255,255,255)";
  const gradientStops = [
    {
      key: "0%",
      stopColor: color(fillColor).darker(0.5).toString(),
      stopOpacity: 1,
      offset: "0%",
    },
    {
      key: "50%",
      stopColor: fillColor,
      stopOpacity: 0.75,
      offset: "50%",
    },
    {
      key: "100%",
      stopColor: color(fillColor).brighter(0.5).toString(),
      stopOpacity: 0.5,
      offset: "100%",
    },
  ];
 

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const [validation, setValidation] = useState({
    dateerror: "",
  });
  let errors = { ...validation };
  let subtitle;
  let detailurl = `${process.env.REACT_APP_DOMAIN}plagiarisam-detail/`;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const arr = [];

  useEffect(() => {
    CloseModalTrigger()


    dispatch(GetCompetitorJobIds(esodata.domaindata.value.header_selected));
    dispatch(GetCompetitorDomainIds(esodata.domaindata.value.header_selected));
    dispatch(GetCompetitorInJobIds(esodata.domaindata.value.header_selected));

   
 
  }, []);
  const columns = [

    {
      field: "id",
      headerName: "#",
      description: 'Index of the rows',
      width: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "seo_metrics",
      headerName: "Seo Metrics",
      description: 'On-page SEO elements are present on the website.',
      width: 250,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
    
    },
    {
      field: "priority",
      headerName: "Priority",
      description: 'Priority Status from competitor test',
      width: 250,
      headerAlign: "center",
      align: "center",
      sortable: true,
  
      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "our_occurences",
      headerName: "Our Occurrence",
      description: 'Our Occurrence count from competitor test',
      width: 300,
   
      headerAlign: "center",
      align: "center",
    },
    {
      field: "comp_occurences",
      headerName: "Competitor Occurrence",
      description: 'Competitor Occurrence count from competitor test',
      width: 300,
      headerAlign: 'center',
      align:'center',

     
    },
  ];
  const column1 = [
    {
      field: "id",
      headerName: "#",
      description: 'Index of the rows',
      width: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "site_metrics",
      headerName: "Site Metrics",
      description: 'Website metrics present on the website',
      width: 250,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,

    },
    {
      field: "priority",
      headerName: "Priority",
      description: 'SEO and site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings.',
      width: 250,
      headerAlign: "center",
      align: "center",
      sortable: true,
   
      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "our_occurences",
      headerName: "Our Occurrence",
      width: 300,
      description: 'The total number of specific metrics present on the website.',
      headerAlign: "center",
      align: "center",
    },
    {
      field: "comp_occurences",
      headerName: "Competitor Occurrence",
      description: 'Competitor Occurrence count from competitor test',
      width: 300,
      headerAlign: 'center',
      align:'center',
     
     
    },
  ];
  const column2 = [

    {
      field: "id",
      headerName: "#",
      description: 'Index of the rows',
      width: 100,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "internal_links",
      headerName: "Internal Metrics",
      description: 'It displays server error links (5xx) and broken links (4xx) on the website.',
      width: 250,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      sortable: true,
 
      
    },
    {
      field: "priority",
      headerName: "Priority",
      description: 'SEO and site parameters can be prioritized and categorized according to your organization’s marketing strategies in the site audit settings.',
      width: 250,
      headerAlign: "center",
      align: "center",
      sortable: true,
    
      renderCell: (cardData) => {
        return (
          <p className="button_score_org">{`${cardData?.row?.priority}`}</p>
        );
      },
    },
    {
      field: "our_occurences",
      headerName: "Our Occurrence",
      width: 300,
      description: 'Our Occurrence count from competitor test',
      headerAlign: "center",
      align: "center",
    },
    {
      field: "comp_occurences",
      headerName: "Competitor Occurrence",
      description: 'Competitor Occurrence count from competitor test',
      width: 300,
      headerAlign: 'center',
      align:'center',
  
     
    },
  ];
  const rows = cardData ?? [];
  const rows1 = sitecardData ?? [];
  const rows2 = internalcardData ?? [];
  const cardrow =competitorcardData ?? [];
  const LoadAddFormFilterOptions = async () => {
 
    if (jobidData === "") {
      const completevalues = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          const datavalue = "";
        });
  if(completevalues?.data?.jobs.length>0){
    setcompletedate(completevalues?.data?.jobs);
    SetdEngineoption(completevalues?.data?.jobs[0])

  }
     
        
    }
  } 
  const callUserdataApi = async (jobid = "",compjobid='',compdomain='') => {
    setLoading(true);



    var datavalue;
    if(jobid!=='' && compjobid!=='' && compdomain!=='')
    {

      
       datavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/competitor_analysis/?job_id=${jobid}&user_domain_id=${esodata.domaindata.value.header_selected}&comp_job_id=${compjobid}&competitor_domain_id=${compdomain}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        const datavalue = "";
      });

    }
    else{

     
   

       datavalue = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/competitor_analysis/?job_id=${JobId}&user_domain_id=${esodata.domaindata.value.header_selected}&comp_job_id=${CompetitorJobId}&competitor_domain_id=${CompetitorDomainId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        const datavalue = "";
      });

    }
  
   
    if (jobidData === "") {
      const completevalues = await axios
        .get(
          `${process.env.REACT_APP_API_URL}domain/get_report_job_details/?job_type=site_audit&user_domain_id=${esodata.domaindata.value.header_selected}&domain_section_id=${esodata.sectiondomaindata.value}`,
          {
            headers: {
              Authorization: `${ApiToken}`,
            },
          }
        )
        .catch((error) => {
          const datavalue = "";
        });
      setcompletedate(completevalues?.data?.jobs);
    }


   
    const dataval = datavalue?.data?.domain?.seo_metrics_list;
    const dataval1 = datavalue?.data?.domain?.site_metrics_list;
    const dataval2 = datavalue?.data?.domain?.internal_links_list;
    const compDataval= datavalue?.data?.competitor?.seo_metrics_list;
    const compDataval1= datavalue?.data?.competitor?.site_metrics_list;
    const compDataval2= datavalue?.data?.competitor?.internal_links_list;
  
    setjobDate(moment(datavalue?.data?.job_date).format("MMMM DD,YYYY "));



    if(dataval && dataval.length>0)
    {
      const newData = dataval.map((obj, index) => {
        return { ...obj, id: index+1 };
      });
  
      setCardData(newData);
      SetAutoheightval(false)
    }
    else{
      setCardData("");
      SetAutoheightval(true)
    }

    if(dataval1)
    {
      const newData = dataval1.map((obj, index) => {
        return { ...obj, id: index+1 };
      });
  
      setSitecardData(newData);
    }

    
    if(dataval2)
    {
      const newData = dataval2.map((obj, index) => {
        return { ...obj, id: index+1 };
      });
  
      setInternalcardData(newData);
    }


 
   
    
    setCompetitorcardData(compDataval)
    setCompetitorSitecardData(compDataval1)
    setCompetitorInternalcardData(compDataval2)

    var datavalueChart;

    if(jobid!=='' && compjobid!=='' && compdomain!=='')
    {

       datavalueChart = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/competitor_analysis_graph/?user_domain_id=${esodata.domaindata.value.header_selected}&competitor_domain_id=${compdomain}&job_id=${jobid}&comp_job_id=${compjobid}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        const datavalueChart = "";
      });

    }else{

       datavalueChart = await axios
      .get(
        `${process.env.REACT_APP_API_URL}technical/competitor_analysis_graph/?user_domain_id=${esodata.domaindata.value.header_selected}&competitor_domain_id=${CompetitorDomainId}&job_id=${JobId}&comp_job_id=${CompetitorJobId}`,
        {
          headers: {
            Authorization: `${ApiToken}`,
          },
        }
      )
      .catch((error) => {
        const datavalueChart = "";
      });

    }


   


    const chartval = datavalueChart?.data?.graph?.issue_graph;

    const seoindex = chartval?.map(function (val, index) {
      return val.seo_metrics;
    });
    const seooccurence = chartval?.map(function (val, index) {
      return val.comp_occurences;
    });
     const latestoccurence = chartval?.map(function (val, index) {
      return val.our_occurences;
    });
 
    setSeoindexData(seoindex);
    setSeooccurData(seooccurence);
    setLatestoccurData(latestoccurence);
    const datahearvals=['High impact Issue Occurrence','Medium Impact Issue Occurrence','Low Impact Issue Occurrence']
    setArrheaderdata(datahearvals)

    const earlier_dataarrvals = [];
    const latest_dataarrvals = [];

    latest_dataarrvals[0]=datavalueChart?.data?.graph?.our_high_count;
    earlier_dataarrvals[0]=datavalueChart?.data?.graph?.comp_high_count;
    latest_dataarrvals[1]=datavalueChart?.data?.graph?.our_medium_count;
    earlier_dataarrvals[1]=datavalueChart?.data?.graph?.comp_medium_count;
    latest_dataarrvals[2]=datavalueChart?.data?.graph?.our_low_count;
    earlier_dataarrvals[2]=datavalueChart?.data?.graph?.comp_low_count;





    setLatestdataarr(latest_dataarrvals)
    setEarlierdataarr(earlier_dataarrvals) 

var severity_dataarrvals=[];

    severity_dataarrvals[0]=datavalueChart?.data?.graph?.our_high_serverity_graph;
    severity_dataarrvals[1]=datavalueChart?.data?.graph?.comp_high_serverity_graph;
    severity_dataarrvals[2]=datavalueChart?.data?.graph?.our_medium_severity_graph;
    severity_dataarrvals[3]=datavalueChart?.data?.graph?.comp_medium_severity_graph;
    severity_dataarrvals[4]=datavalueChart?.data?.graph?.our_low_severity_graph;
    severity_dataarrvals[5]=datavalueChart?.data?.graph?.comp_low_severity_graph;
    severity_dataarrvals[6]=datavalueChart?.data?.domain_name ;
    severity_dataarrvals[7]=datavalueChart?.data?.comp_domain_name;
    severity_dataarrvals[8]=(moment(datavalueChart?.data?.job_date).format("MMM DD,YYYY "));
    severity_dataarrvals[9]=(moment(datavalueChart?.data?.comp_job_date).format("MMM DD,YYYY "));
    setServeritydata(severity_dataarrvals)

    setLoading(false)


  };
  const barChartData = {
    labels:seoindexData ,
    datasets: [
      {
        label: 'Competitor Domain',
        data: seooccurData,
        backgroundColor:  ["#103658"],
      },
      {
        label: 'Our Domain',
        data: latestoccurData,
        backgroundColor: [ "#fe874e"],
      },
     
    ],
  };
  const reportNow = async () => {
    setLoading(true);
    var errs = 1;
    let errors = {};

    if (selectval === "") {
      errors.dateerror = "This field is required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }
    setjobidData(selectval[0].id);
    closeModal();
    callUserdataApi(selectval[0].id);
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  // competitor test filter

  const dispatch = useDispatch();



  const esodata = useSelector((state) => state);
  const ApiToken=esodata.apiauthkey.value





 const [CompetitorJobId,SetCompetitorJobId]=useState('')
 const [CompetitorDomainId,SetCompetitorDomainId]=useState('')
 const [JobId,SetJobId]=useState('')


  const CjdomainvaldataChange=(value)=>{
    SetCompetitorJobId(value[0].value)

  }

  const CddomainvaldataChange=(value)=>{
    SetCompetitorDomainId(value[0].value)

  }
  const JdomainvaldataChange=(value)=>{
    SetJobId(value[0].value)

  }




  const CloseModalTrigger=()=>{

    SetCompetitorJobId('')
    SetCompetitorDomainId('')
    SetJobId('')
 
    props.setIsOpen(false)
  }

  const FetchCompetitorList=()=>{


    var errs = 1;
    let errors = {};
  
    if (CompetitorJobId === "" || CompetitorDomainId==='' || JobId==='') {
      errors.dateerror = "All fields are required.";
      errs = 2;
    } else {
      errors.dateerror = "";
      errs = 1;
      setValidation(errors);
    }
    if (errs === 2) {
      return setValidation(errors);
    }


    if(CompetitorJobId!=='' && CompetitorDomainId!=='' && JobId!=='')
    {
      callUserdataApi()
      props.setIsOpen(false)
      SetCompetitorJobId('')
      SetCompetitorDomainId('')
      SetJobId('')
    }
 
  }
 
  // competitor test filter End

  //competitor chart analysis  start

  const [chartvalue, setChartvalue] = useState("4");
  const handlechartChange = (event, newValue) => {
    setChartvalue(newValue);
  };

  const barChartData1 = {
    labels: arrheaderdata,
    datasets: [
      {
        label: 'Competitor Domain',
        data: earlierdataarr,
        backgroundColor:  ["#103658"],
      },
      {
        label: 'Our Domain',
        data: latestdataarr ,
        backgroundColor: [ "#fe874e"],
      },
     
    ],
  };


  useEffect(()=>{

  


    if(esodata.competitorinjobids.value[0] && esodata.competitorjobids.value[0] && esodata.competitordomainids.value[0])
    {

      if(esodata.competitorinjobids.value[0].value && esodata.competitorjobids.value[0].value && esodata.competitordomainids.value[0].value)
    {
    
      callUserdataApi(esodata.competitorinjobids.value[0].value,esodata.competitorjobids.value[0].value,esodata.competitordomainids.value[0].value)
    }


    }

    

    LoadAddFormFilterOptions()
  },[esodata.competitorinjobids,esodata.competitorjobids,esodata.competitordomainids])
  


  //competitor chart analysis  end
 
  return (
    <>
    
    {/* {
      loading===true ?   <Loader/> : null
    } */}
  

<div className="tabs_graph_outer_wrap">
      <TabContext value={chartvalue}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handlechartChange} aria-label="lab API tabs example" >
              <Tab className="tabbutton" label="By Issue" value="4" />
              <Tab className="tabbutton"  label="By Severity" value="5" />
            </TabList>
          </Box>
          <TabPanel value="4">
      <div className="site_regress_chart_info">
        {/* <p>Site Audit Summary by Issues</p> */}
        <div className="site_Chart_info_inner">
          <Bar
            type="bar"
            width={130}
            height={50}
            data={barChartData}
            options={{
              responsive: true,
              plugins: {
                
                // 'legend' now within object 'plugins {}'
                legend: {
                  position: 'top',
                  display: true,
                  labels: {
                    color: '#0b2033',
                    font: {
                      size: 15,
                      family:'Open Sans',
                    }
                  }
                  
                },
                title: {
                  display: true,
                  text: 'competitor-test Summary By Issue',
                  color: '#0b2033',
                    font: {
                      size: 18,  
                      family:'Open Sans',                 
                    }
                  
                },
              },
              scales: {
                y: {
                  // not 'yAxes: [{' anymore (not an array anymore)
                  ticks: {
                    suggestedMin: 0,
                    stacked: true,
                    color: "#0b2033", // not 'fontColor:' anymore
                    // fontSize: 18,
                    font: {
                      size: 14, // 'size' now within object 'font {}'
                      family:'Open Sans',
                    },
                    beginAtZero: true,
                  },
                },
                x: {
                  // not 'xAxes: [{' anymore (not an array anymore)
                  ticks: {
                    color: "#0b2033", // not 'fontColor:' anymore
                    //fontSize: 14,
                    font: {
                      size: 12, // 'size' now within object 'font {}'
                      family:'Open Sans',
                    },
                    beginAtZero: true,
                  },
                },
              },
            }}
          />
        </div>
      
      </div>
      </TabPanel>
      <TabPanel value="5">
      <div className="site_regress_chart_info">
        {/* <p>Site Audit Summary by Issues</p> */}
        <div className="site_Chart_info_inner">
          <Bar
            type="bar"
            width={130}
            height={50}
            data={barChartData1}
            options={{
              responsive: true,
              plugins: {
                
                // 'legend' now within object 'plugins {}'
                legend: {
                  position: 'top',
                  display: true,
                  labels: {
                    color: '#103658',
                    font: {
                      size: 15,
                      family:'Open Sans',
                    }
                  }
                  
                },
                title: {
                  display: true,
                  text: 'competitor-test Summary By Severity',
                  color: '#0b2033',
                    font: {
                      size: 18,  
                      family:'Open Sans',                 
                    }
                  
                },
              },
              scales: {
                y: {
                  // not 'yAxes: [{' anymore (not an array anymore)
                  ticks: {
                    suggestedMin: 0,
                    stacked: true,
                    color: "#0b2033", // not 'fontColor:' anymore
                    // fontSize: 18,
                    font: {
                      size: 14, // 'size' now within object 'font {}'
                      family:'Open Sans',
                    },
                    beginAtZero: true,
                    
                  },
                },
                x: {
                  // not 'xAxes: [{' anymore (not an array anymore)
                  ticks: {
                    color: "#0b2033", // not 'fontColor:' anymore
                    //fontSize: 14,
                    font: {
                      size: 12, // 'size' now within object 'font {}'
                      family:'Open Sans',
                    },
                    beginAtZero: true,
                  },
                },
              },
            }}
          />
        </div>

        <table>
          <tr className="table_regression">
            <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]}) - {serveritydata[0]}%</td>
            <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]}) - {serveritydata[2]}%</td>
            <td className="table_td_regression">{serveritydata[6]}( {serveritydata[8]})- {serveritydata[4]}%</td>
          </tr>
          <tr className="table_regression">
            <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[1]}%</td>
            <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[3]}%</td>
            <td className="table_td_regression">{serveritydata[7]}( {serveritydata[9]}) - {serveritydata[5]}%</td>
          </tr>
        </table>
       
      </div>
      </TabPanel>
      </TabContext>
      </div>
     
      <div className="tabs_outer_wrap tabs_outer_wrap_tab_btm_rem">
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="SEO Metrics" value="1" />
              <Tab label="Site Metrics" value="2" />
              <Tab label="Internal Links" value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 550, width: "100%" }}>
                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                    ".MuiDataGrid-virtualScroller": {
                      height: "auto !important",
                      overflowY: "auto",
                    },
                    "& .MuiDataGrid-cell:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      zIndex: 999,
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      position: "sticky",
                      top: "0",
                      left: "0",
                      paddingLeft: "1.5rem",
                      border: "none",
                      zIndex: 999,
                    },
                  }}
                  autoHeight={autoheightval?true:false}   
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.seo_metrics}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 10,
                      },
                    },
                  }}
                  pageSizeOptions={[10, 30, 50, 75, 100]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="2">
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 750, width: "100%" }}>
                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                  }}
                  autoHeight
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.site_metrics}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows1}
                  columns={column1}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel value="3">
            <div className="data_table" style={{ width: "100%" }}>
              <div style={{ height: 750, width: "100%" }}>
                <DataGrid
                  sx={{
                    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
                      py: "8px",
                    },
                    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
                      py: "15px",
                    },
                    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
                      py: "22px",
                    },
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "&, [class^=MuiDataGrid]": { border: "none" },
                  }}
                  autoHeight
                  getRowHeight={() => 'auto'}
                  getRowId={(row) => row.internal_links}
                  slots={{
                    noRowsOverlay: MyCustomNoRowsOverlay,
                    toolbar: GridToolbar
                  }}
                  slotProps={{
                    toolbar: {
                  
                      showQuickFilter: true,
                    
                    },
                  }}
                  rows={rows2}
                  columns={column2}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 5,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 10, 25]}
                  disableColumnFilter
                  disableColumnSelector
                  disableDensitySelector
                />
              </div>
            </div>
          </TabPanel>
        </TabContext>
      </div>

      <Dialog
        open={props.modalIsOpen}
        onClose={CloseModalTrigger}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="competitor_test_filter"
      >
        <DialogTitle>{"Filter By"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="cnt_hdr_blw_wrap row">
            
            <div>

            <div className="">
                <div>
                  <span>Completed Job Date</span>
                  {/* <Select
                    options={esodata.competitorinjobids.value}
                    onChange={JdomainvaldataChange}
                  /> */}
                    <Select
                    values={[dEngineoption]}
                    options={completedate}
                    labelField="completed_at"
                    valueField="id"
                    onChange={(values) => {SetJobId(values[0]?.id)
                      SetdEngineoption({
                        completed_at:
                          values[0]?.completed_at,
                          id:
                          values[0]?.id,
                      });                    
                    }}
                
                    />
                </div>
           
               
              </div>
            

                <div className="">
                <div>
                  <span>Competitor Job Date</span>
                  <Select
                    options={esodata?.competitorjobids?.value}
                    onChange={CjdomainvaldataChange}
                  />
                </div>
           
               
              </div>
              <div className="">
                <div>
                  <span>Competitor Domain</span>
                  <Select
                    options={esodata?.competitordomainids?.value}
                    onChange={CddomainvaldataChange}
                  />
                </div>
           
               
              </div>

              <div className="col">
                <span className="text-error">{validation.dateerror}</span>
              </div>

             
              </div>
           
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button className="run_rpt_btn rn_rpt_btn" onClick={FetchCompetitorList} style={{cursor:'pointer'}} >
            Submit
          </button>
          <button style={{cursor:'pointer'}} onClick={CloseModalTrigger}>close</button>
        </DialogActions>
      </Dialog>
      </>
  );
}
